import PendingActionDetails from "../components/PendingActionDetails";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from './../config';

const PendingActions = () => {

  const email1 = useSelector((state) => state.user.email);
  const navigate = useNavigate();
  const [baskets, setBaskets] = useState([])
  const [err, setErr] = useState("");
  const [messageType, setMessageType] = useState("");
  /* useEffect(() => {
    if (email1 === null || email1 === "") {
      navigate("/")
    }
  }) */
  useEffect(() => {
    getPendingActions()
  }, [])

  useEffect(() => {
    let interval;
    const hasErrorCount = baskets.some(basket =>
      (basket.status === 'inserted' || basket.status === 'success') && basket.errorCount > 0
    );
    if (hasErrorCount) {
      interval = setInterval(() => {
        getPendingActions();
      }, 20000); // 20 seconds
    }
    return () => clearInterval(interval);
  }, [baskets]);

  const getPendingActions = async (e) => {

    try {

      const res = await axios.get(config.BL_API_URL + "/transaction/v1/getTransactionErrors")
      //console.log(res)

      if (res.data)
        setBaskets(res.data)
      else
        setErr("backend error")

    } catch (err) {
      //console.log("error 333 ", err)
      //if (err.response.status === 500)
      setErr("Server error...");
      //else
      //    setErr(err.response.data);
      if (err?.response?.data) setErr(err.response.data);
    }
  }

  const groupedBaskets = baskets.reduce((groups, item) => {
    const status = item.status ? item.status : 'open';
    const key = `${item.basketId}-${status}`;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
    return groups;
  }, {});
  const keys = Object.keys(groupedBaskets);

  return (
    <div className=" my-14 md:px-14 max-w-screen-2xl mx-auto ">
      <div className="py-1 px-1 flex justify-center items-center">
        <span className="mb-6 ml-4 text-2xl ">Pending Actions Reports</span>
      </div>
      {/*       <div className="py-1 px-1 flex justify-center items-center">
        <select>
          <option value="0">None</option>
          <option value="1">Orders Rejected</option>
          <option value="2">Order Generated But no yet sent for execution</option>
        </select>
      </div> */}
      {/* <div className="mx-24">
        {baskets.map((basket) => (
          <PendingActionDetails basketId={basket.basketId} keyName={basket.basketName} basket={basket} status={basket.status} />
        ))}
      </div>       */}
      {/* {err} */}
      {keys.length > 0 ? (
        keys.map((key) => (
          <div key={key}>

            {groupedBaskets[key].map((basket) => (
              <PendingActionDetails basketId={basket.basketId} keyName={basket.basketName} basket={basket} status={key.substring(key.indexOf('-') + 1)} />
            ))}
          </div>
        ))
      ) : (
        <div className="py-1 px-1 flex justify-center items-center">
          <span className="mb-6 ml-4 text-lg ">No records</span>
        </div>
      )}

    </div>
  )
}

export default PendingActions