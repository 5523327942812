import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import config from '../../config';
import loadingImg from "../../assets/loading.gif"
import { set } from 'date-fns';

const AsOnHoldingReport = () => {
    const companyId = useSelector((state) => state.user.companyId);

    const [basketClientHolding, setBasketClientHolding] = useState([])
    const [err, setErr] = useState("")
    const [inputs, setInputs] = useState({
        basketId: null,
        clientCode: "",
        asOnDate: null
    })
    const [isLoading, setIsLoading] = useState(false)
    const [currentDate, setCurrentDate] = useState('')
    const [baskets, setBaskets] = useState([])

    const email = useSelector((state) => state.user.email);
    const navigate = useNavigate();
    const [totalCurrentValue, setTotalCurrentValue] = useState(0);

    useEffect(() => {
        if (email === null || email === "") {
            navigate("/")
        }
    })

    useEffect(() => {
        getBaskets();
        /*        const today = new Date();
                const dateString = today.toISOString().substring(0,10);
                setCurrentDate(dateString);
                inputs.fromDate = dateString;*/
        //handleSearch(null);
        //getCashLedger();
    }, [])
    const getBaskets = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/basket/v1");

            if (res.data)
                setBaskets(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }



    const handleClearSearch = async (e) => {
        let clientCode = document.getElementById("client-Code");
        let asOnDate = document.getElementById("asOnDate");
        let basketId = document.getElementById("basketId");
        clientCode.value = ""
        asOnDate.value = ""
        basketId.value = ""
        setInputs({
            clientCode: "",
            asOnDate: null,
            basketId: null,
        });
        setBasketClientHolding([]);
    }

    const handleSearch = async (e) => {
        try {
            setIsLoading(true);
            setTotalCurrentValue(0);
            const res = await axios.post(config.BL_API_URL + "/csvReport/v1/getAsOnHolding", inputs);
            if (res.data) {
                setBasketClientHolding(res.data)
                const totalCurrentValue1 = basketClientHolding.reduce((total, holding) => total + holding.currentValue, 0);
                console.log(totalCurrentValue1)
                setTotalCurrentValue(totalCurrentValue1);
                setIsLoading(false);
            }
            else {
                setTotalCurrentValue(0);
                setErr("backend error")
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }



    function formatNumber(value) {
        return new Intl.NumberFormat('en-IN').format(value);
    }

    function formatDate(value) {
        if (value == null || value === undefined || value === "") {
            return;
        }
        return new Intl.DateTimeFormat('en-IN').format(value);
    }
    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }


    return (
        <div className="my-12 md:px-14 p-4 max-w-screen-md mx-auto ">
            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">

                    <div className="border rounded-sm divide-y divide-gray-200 ">
                        <div className='col-span-5'>
                            <div className=" col-span-2">
                                <div className="py-3 px-4 flex justify-center items-center">
                                    <span className="text-gray-500 text-2xl font-bold">As-On Holding Report</span>
                                </div>
                                <div className='col-span-5 flex  gap-x-1 px-1 py-1 overflow-ellipsis'>
                                    <div className="grid grid-cols-5 gap-2 justify-start border-0 border-red-500">
                                        <div className="col-span-1 gap-5 text-xs ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                                <legend className='m-1 p-0'>Basket Name</legend>
                                                <select
                                                    onChange={handleChange}
                                                    name="basketId" id="basketId"
                                                    className="py-0 px-0 pe-0 block w-full focus:outline-none">
                                                    <option value="">-select-</option>
                                                    {baskets && (
                                                        baskets.map((basket, index) => <option key={index} value={basket.id}>{basket.name}</option>)
                                                    )}
                                                </select>
                                            </fieldset>
                                        </div>
                                        <div className="col-span-1 gap-5 text-xs  ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                                <legend className='m-1 p-0'>Client Code</legend>
                                                <input id="client-Code" w-full name="clientCode" onChange={handleChange} placeholder='Search by client code' className=" py-0 px-0 pe-0 block focus:outline-none" />
                                            </fieldset>
                                        </div>

                                        <div className="col-span-1 gap-5 text-xs px-5 ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0 '>
                                                <legend className='m-1 p-0'>As On Date</legend>
                                                <input
                                                    onChange={handleChange}
                                                    type="date" name="asOnDate" id="asOnDate" placeholder="asOndate"
                                                    className="py-0 px-0 pe-0 w-full block  focus:outline-none "
                                                />
                                            </fieldset>
                                        </div>

                                        <div className="col-span-1 gap-1 text-center text-xs " >
                                            <button type="button" onClick={e => handleSearch()} className="py-2 px-2 inline-flex right-0 gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">

                                                <span>Search</span>
                                                {isLoading &&
                                                    <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                                                }

                                            </button>
                                        </div>
                                        <div className="col-span-1 gap-1 text-center text-xs " >

                                            <button type="button" onClick={e => handleClearSearch()} className="py-2 px-2 inline-flex right-0 gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">
                                                Clear Search

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-hidden">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200  ">
                                    <thead className="bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">#</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Scrip Name</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Quantity</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Closing Price</th>
                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {basketClientHolding &&

                                            basketClientHolding.map((holding, index) => (
                                                <tr>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{index + 1}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{holding.ticker}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{formatNumber(holding.qty)}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{formatNumber(holding.currentPrice)}</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{formatNumber(holding.currentValue)}</td>
                                                </tr>
                                            ))}
                                        {(basketClientHolding === null || basketClientHolding.length <= 0) &&
                                            <tr><td colSpan={9} className='text-center text-red-500 '>No records to display!</td></tr>
                                        }
                                        {basketClientHolding && basketClientHolding.length > 0 && (
                                            <>
                                                <tr>
                                                    <td colSpan={5} className="border-t-2 border-gray-300"></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} className="px-6 py-1 whitespace-nowrap text-sm font-bold text-gray-800">Total</td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-sm font-bold text-gray-800">{formatNumber(totalCurrentValue)}</td>
                                                </tr>
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AsOnHoldingReport;